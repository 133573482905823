.navbar {

  width:100vw;
  min-width: 100%;
  align-items: baseline;
  padding:2% 2% 1% 0;
  background-color: #F8DFDB;
}
.nav-link:hover {
  text-decoration: underline;
}

.active {
  text-decoration: underline;
}
.navbar-nav {
  align-items: center;
}

.navbar-collapse.collapse {
  justify-content: flex-end;
}

.navbar-collapse.collapse.show {
  color:black;
  /* background-color: rgba(255, 255, 255, 0.4); */
  margin-left:auto;

}
.navbar-collapse.collapse.show > .ml-auto{
  color:black;
  background-color: rgba(255, 255, 255, 0.4);
  margin-left:auto;
}
