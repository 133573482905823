#contact-container {
  width:100%;
  padding:0%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mb-3 {
  display:inline;
  width:48%
}

.text-muted {
  font-weight: 300;
  font-size: small;
}


#myForm {
  width:70vw;
  margin:auto;
}
#form-btn {
  width:100%;
  font-family: "Shantell Sans";
  font-size: larger;
}
img {
  max-width: 100%;
  max-height: 100%;
}

/* ========= Resume donwload ========= */
.contact-direct {
  color:#632B30;
  border: solid 3px #C17178;
  background-color: transparent;
  text-align:left;
  display:flex;
  width:100%;
  align-items:center;
  background-color: #F7ECEA;
}
.contact-direct:hover{
  background-color: #C17178;
  border:none;
  color:white
}
.contact-direct.single,
#form-btn {
  background-color: #C17178;
  border:none;
  color:white
}

.contact-direct.single:hover,
#form-btn:hover {
  background-color: #d6a3a7;
  border:none;
  color:brown
}

.contact-direct > img{
  width:60px;
  padding:1%;
  margin-right:3%;
}
.contact-direct > span {
  font-size: 1.2rem;
  width:16vw;
}
